<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 class="overlay">
        <v-card outlined class="primary--border">
          <v-card-title class="title"> Billing Month Setting</v-card-title>
          <v-spacer></v-spacer>
          <!-- <v-card> &nbsp; </v-card> -->
          <v-divider></v-divider>

          <v-flex xs12 pa-5>
            <alert-message
              type="warning"
              title="Billing Month Setting Alert!!!"
            >
              <template v-slot:message>
                Please be careful while setting up the billing months. This will
                impact the month selecton on monthly bill generate at <br />
                <strong>Billing > Activity > Monthly Bill Generate</strong>.
                Only operate this setting under supervision from our support.
              </template>
            </alert-message>
          </v-flex>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            hide-default-footer
            :server-items-length="items.length"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td>
                  <strong>
                    {{
                      index > 2 && item.billing === '1st'
                        ? 'Baisakh(13)'
                        : index > 2 && item.billing === '2nd'
                        ? 'Jestha(14)'
                        : getMonthNameFromString(
                            billingMonthConversion(item.billing, false, false)
                          )
                    }}
                  </strong>
                </td>
                <td>
                  <v-select
                    class="mb-1 mt-1"
                    @change="(disabledUpdate = false), (item.is_update = true)"
                    v-model="item.generate_on"
                    :items="months.filter((month) => month.value <= index)"
                    dense
                    outlined
                  >
                  </v-select>
                </td>
                <td>
                  <v-select
                    disabled
                    v-if="multiple === true"
                    class="mb-1 mt-1"
                    :multiple="multiple"
                    @change="(disabledUpdate = false), (item.is_update = true)"
                    v-model="item.generate_month"
                    :items="[...months]"
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    v-else
                    disabled
                    class="mb-1 mt-1"
                    :multiple="false"
                    @change="(disabledUpdate = false), (item.is_update = true)"
                    v-model="item.generate_month"
                    :items="[...months]"
                    dense
                    outlined
                  ></v-select>
                </td>
              </tr>
            </template>
          </v-data-table>
          <br />
          <edit-button
            @agree="saveRecord"
            icon="dd"
            :disabled="disabledUpdate"
            :outlined="false"
            :large="true"
            :block="true"
            color="primary"
            :label="`Update All Records`"
            :permission="true"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Mixins from '@/library/Mixins';
import {
  billingMonthConversion,
  getMonthNameFromString,
} from '@/library/helpers';
export default {
  mixins: [Mixins],
  data: () => ({
    disabledUpdate: true,
    items: [],
    loading: false,
    months: [
      { value: '01', text: 'Baishakh' },
      { value: '02', text: 'Jestha' },
      { value: '03', text: 'Ashad' },
      { value: '04', text: 'Shrawan' },
      { value: '05', text: 'Bhadra' },
      { value: '06', text: 'Asoj' },
      { value: '07', text: 'Kartik' },
      { value: '08', text: 'Mangsir' },
      { value: '09', text: 'Poush' },
      { value: '10', text: 'Magh' },
      { value: '11', text: 'Falgun' },
      { value: '12', text: 'Chaitra' },
      { value: '13', text: 'Baishakh(13)' },
      { value: '14', text: 'Jestha(14)' },
    ],
    multiple: false,
    headers: [
      { text: 'SN', align: 'left', value: 'id', width: 10, sortable: false },
      {
        text: 'Month',
        align: 'left',
        value: 'month',
        sortable: false,
      },
      {
        text: 'Generate On',
        align: 'left',
        value: 'generate_on',
        sortable: false,
      },
      {
        text: 'Generate Month (Multiple months generation is disabled)',
        align: 'left',
        value: 'generate_month',
        width: 600,
        sortable: false,
      },
    ],
  }),
  mounted() {
    this.getBillingSetting().then(() => {
      this.get();
    });
  },
  computed: {
    allSelectedGenerateOn() {
      let output = [];
      this.items.map((item) => {
        output = [...output, ...item.generate_month_fixed];
      });
      return output;
    },
  },

  methods: {
    billingMonthConversion,
    getMonthNameFromString,
    getBillingSetting() {
      return this.$rest.get('/api/billing-setting').then(({ data }) => {
        this.multiple = data.billing.multiple_month === 'true' ? true : false;
      });
    },
    get() {
      this.$rest
        .get(`api/billing-month`)
        .then(({ data }) => {
          this.loading = true;
          this.items = data.data.map((item) => {
            item.generate_month_fixed = item.generate_month;
            if (this.multiple === false) {
              item.generate_month = item.generate_month[0];
              item.generate_month_fixed = item.generate_month[0];
            }
            return item;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveRecord() {
      this.$rest
        .post(`api/billing-month`, {
          params: this.items.map((item) => ({
            ...item,
          })),
        })
        .then(({ data }) => {
          this.get();
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
        })
        .catch((err) => {
          this.$events.fire('notification', {
            message: err.response.data.message,
            status: 'error',
          });
        });
    },
  },
};
</script>
